




































import VSelectWithValidation from '@/components/VSelectWithValidation.vue';
import VTextFieldWithValidation from '@/components/VTextFieldWithValidation.vue';
import { IRateLimit } from '@/interfaces';
import { dispatchGetRateLimit, dispatchUpdateRateLimit } from '@/store/main/accessors';
import { extend, ValidationProvider } from 'vee-validate';
import { Component, Vue } from 'vue-property-decorator';
import { min_value } from 'vee-validate/dist/rules';

extend('min_value', min_value);

@Component({
  components: {
    VTextFieldWithValidation,
    VSelectWithValidation,
  },
})
export default class EditNewsletter extends Vue {
  public periods = [
    { name: this.$vuetify.lang.t('$vuetify.oneMinute'), duration: 60 },
    { name: this.$vuetify.lang.t('$vuetify.oneHour'), duration: 60 * 60 },
    { name: this.$vuetify.lang.t('$vuetify.oneDay'), duration: 60 * 60 * 24 },
  ];
  public periodItems = this.periods.map((p) => p.name);
  public periodName = this.periods[0].name;
  public limit = 0;
  public commentary = '';

  public isValid = true;
  public $refs!: {
    observer: InstanceType<typeof ValidationProvider>;
  };

  public async getRateLimit() {
    const rateLimit = await dispatchGetRateLimit(this.$store, Number(this.$router.currentRoute.params.id));
    if (!rateLimit) {
      this.returnToList();
      return;
    }

    this.periodName = this.periods.find((p) => p.duration === rateLimit.duration)!.name;
    this.limit = rateLimit.limit;
    this.commentary = rateLimit.commentary;
  }

  public mounted() {
    this.getRateLimit();
  }

  public async reset() {
    this.getRateLimit();
  }

  public cancel() {
    this.$router.back();
  }

  public async submit() {
    if (await this.$refs.observer.validate()) {
      const { name, duration } = this.periods.find((p) => p.name === this.periodName)!;
      const rateLimit: IRateLimit = {
        id: Number(this.$router.currentRoute.params.id),
        name,
        limit: this.limit,
        duration,
        commentary: this.commentary,
      };
      const result = await dispatchUpdateRateLimit(this.$store, rateLimit);
      if (result) {
        this.returnToList();
      }
    }
  }

  public returnToList() {
    this.$router.push({ name: 'main-rate-limits-all' });
  }
}
